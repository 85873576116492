import { Cancel, Delete, Edit, Save } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

const EventTypeCard = ({
  category,
  event,
  onDelete,
  onSave,
  onCancel,
  keys,
}) => {
  const [isEditing, setIsEditing] = useState(event.key === "");
  const [editedEvent, setEditedEvent] = useState(event);
  const [eventKey] = useState(event.key);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleDeleteClick = () => {
    if (onDelete) {
      onDelete(category, eventKey);
    }
  };

  const handleSaveClick = () => {
    if (onSave) {
      onSave(category, eventKey, editedEvent);
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setEditedEvent(event);
    setIsEditing(false);

    if (onCancel) {
      onCancel(category, eventKey);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedEvent({ ...editedEvent, [name]: value });
  };

  const handleKeyChange = useCallback(
    (e) => {
      const { value: newValue } = e.target;
      setEditedEvent({ ...editedEvent, key: newValue });
    },
    [editedEvent]
  );

  const availableKeys = useMemo(() => {
    return keys.filter((key) => key !== editedEvent.key);
  }, [keys, editedEvent]);

  const isKeyEmpty = useMemo(() => {
    return editedEvent.key === "" || editedEvent.key === null;
  }, [editedEvent]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid2 container spacing={2}>
          {isEditing ? (
            <>
              <Grid2 size={6}>
                <Autocomplete
                  freeSolo
                  options={availableKeys}
                  value={editedEvent.key}
                  onChange={(event, newValue) => {
                    handleKeyChange({
                      target: {
                        name: "key",
                        value: newValue,
                      },
                    });
                  }}
                  onInputChange={(event, newValue) => {
                    handleKeyChange({
                      target: {
                        name: "key",
                        value: newValue,
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Clé"
                      name="key"
                      fullWidth
                      margin="normal"
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={6}>
                <TextField
                  label="Nom"
                  name="name"
                  value={editedEvent.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid2>
              <Grid2
                size={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={handleSaveClick}
                  startIcon={<Save />}
                  disabled={isKeyEmpty}
                >
                  Enregistrer
                </Button>
                <Button
                  onClick={handleCancelClick}
                  startIcon={<Cancel />}
                  color="secondary"
                >
                  Annuler
                </Button>
              </Grid2>
            </>
          ) : (
            <>
              <Grid2
                size={{ xs: 6, sm: 5 }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  Clé
                </Typography>
                <Typography variant="body1">{event.key}</Typography>
              </Grid2>
              <Grid2
                size={{ xs: 6, sm: 5 }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  Nom
                </Typography>
                <Typography variant="body1">{event.name}</Typography>
              </Grid2>
              <Grid2
                size={{ xs: 12, sm: 2 }}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton onClick={handleEditClick}>
                  <Edit />
                </IconButton>
                <IconButton onClick={handleDeleteClick}>
                  <Delete />
                </IconButton>
              </Grid2>
            </>
          )}
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default EventTypeCard;
