import "moment/locale/fr";
import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { RecoilEnv, RecoilRoot } from "recoil";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { frFR } from "@mui/x-date-pickers/locales";
import React from "react";
import Account from "./Components/Account";
import { UserPaymentConfiguration } from "./Components/Account/Payment/UserPaymentConfiguration";
import UserPaymentHome from "./Components/Account/Payment/UserPaymentHome";
import { UserPaymentInfos } from "./Components/Account/Payment/UserPaymentInfos";
import { UserPaymentReturn } from "./Components/Account/Payment/UserPaymentReturn";
import Riders from "./Components/Account/Riders";
import Administration from "./Components/Administration";
import AdministrationHome from "./Components/Administration/AdministrationHome/AdministrationHome";
import Billing from "./Components/Administration/Billing/Billing";
import ClubSettings from "./Components/Administration/ClubSettings/ClubSettings";
import ContestParameters from "./Components/Administration/ContestParameters/ContestParameters";
import EventsType from "./Components/Administration/EventsType/EventsType";
import UserManagament from "./Components/Administration/UserManagement/UserManagement";
import { AppErrorBoundary } from "./Components/AppErrorBoundary/AppErrorBoundary";
import AppHelmet from "./Components/AppHelmet/AppHelmet";
import ContestsHome from "./Components/ContestsHome";
import ContestManage from "./Components/ContestsHome/ContestManage";
import ContestRegister from "./Components/ContestsHome/ContestRegister";
import Contests from "./Components/ContestsHome/Contests";
import CreateContest from "./Components/CreateContest";
import Payment from "./Components/CreateContest/Payment";
import Home from "./Components/Home";
import ActionCards from "./Components/Home/ActionCards";
import HorseCalendar from "./Components/HorseCalendar/HorseCalendar";
import HorseList from "./Components/HorseCalendar/HorseList/HorseList";
import HorseCalendarAdd from "./Components/HorseCalendar/HorsePage/HorseCalendarAdd";
import HorseCalendarEdit from "./Components/HorseCalendar/HorsePage/HorseCalendarEdit";
import HorsePage from "./Components/HorseCalendar/HorsePage/HorsePage";
import Loading from "./Components/Loading";
import Login from "./Components/Login";
import CreateAccount from "./Components/Login/CreateAccount";
import Facebook from "./Components/Login/Facebook";
import ForgetAccount from "./Components/Login/ForgetPassword";
import ResetPassword from "./Components/Login/ResetPassword";
import ValidateAccount from "./Components/Login/ValidateAccount";
import Maintenance from "./Components/Maintenance";
import MySubscriptions from "./Components/MySubscriptions";
import Subscriptions from "./Components/MySubscriptions/Subscriptions";
import Page403 from "./Components/Page403";
import Page404 from "./Components/Page404";
import Confidentiality from "./Components/Policies/Confidentiality";
import ServiceConditions from "./Components/Policies/ServiceConditions";
import SnackBar from "./Components/SnackBar";
import { messages } from "./locales/fr";

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#5a5aa8",
    },
    declined: {
      main: "#ffd9c6",
      contrastText: "#00000",
    },
    passed: {
      main: "#d0f5ff",
      contrastText: "#00000",
    },
    archived: {
      main: "#e0e0e0",
      contrastText: "#00000",
    },
  },
  frFR,
});

i18n.load("fr", messages);
i18n.activate("fr");
i18n.loadLocaleData({
  fr: { plurals: messages },
});

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true,
      },
    },
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <AppErrorBoundary />,
      children: [
        {
          element: <ActionCards />,
          index: true,
        },
      ],
    },
    {
      path: "/login",
      errorElement: <AppErrorBoundary />,
      children: [
        {
          element: <Login />,
          index: true,
          path: "",
        },
        {
          element: <Facebook />,
          path: "facebook",
        },
        {
          element: <CreateAccount />,
          path: "create-account",
        },
        {
          element: <ForgetAccount />,
          path: "forgot-password",
        },
        {
          element: <ResetPassword />,
          path: "reset-password/:token",
        },
        {
          element: <ValidateAccount />,
          path: "validate-account/:token",
        },
      ],
    },
    {
      path: "/create-contest",
      errorElement: <AppErrorBoundary />,
      children: [
        {
          element: <CreateContest />,
          index: true,
        },
        {
          element: <Payment />,
          path: "payment/:contestGuid",
        },
      ],
    },
    {
      path: "/modify-contest/:contestGuid",
      element: <CreateContest />,
      errorElement: <AppErrorBoundary />,
    },
    {
      path: "/contests",
      element: <ContestsHome />,
      errorElement: <AppErrorBoundary />,
      children: [
        {
          element: <Contests />,
          index: true,
        },
        {
          element: <ContestRegister />,
          path: ":contestGuid/register",
        },
        {
          element: <ContestManage />,
          path: ":contestGuid/manage",
        },
        {
          element: <ContestRegister />,
          path: ":contestGuid/update/:entryGuid",
        },
      ],
    },
    {
      path: "/my-subscriptions",
      element: <MySubscriptions />,
      errorElement: <AppErrorBoundary />,
      children: [
        {
          element: <Subscriptions />,
          index: true,
        },
      ],
    },
    {
      path: "/account",
      element: <Account />,
      errorElement: <AppErrorBoundary />,
    },
    {
      path: "/riders",
      element: <Riders />,
      errorElement: <AppErrorBoundary />,
    },
    {
      path: "/payment-infos",
      element: <UserPaymentHome />,
      errorElement: <AppErrorBoundary />,
      children: [
        {
          element: <UserPaymentInfos />,
          index: true,
        },
        {
          element: <UserPaymentConfiguration />,
          path: "setup",
        },
        {
          element: <UserPaymentReturn />,
          path: "return/:sessionId",
        },
      ],
    },
    {
      path: "/administration",
      element: <Administration />,
      errorElement: <AppErrorBoundary />,
      children: [
        {
          element: <AdministrationHome />,
          index: true,
        },
        {
          element: <UserManagament />,
          path: "users",
        },
        {
          element: <ContestParameters />,
          path: "contest-parameters",
        },
        {
          element: <ClubSettings />,
          path: "club-settings",
        },
        {
          element: <Billing />,
          path: "billing",
        },
        {
          element: <EventsType />,
          path: "events-type",
        },
      ],
    },
    {
      path: "/horse-calendar",
      element: <HorseCalendar />,
      errorElement: <AppErrorBoundary />,
      children: [
        {
          element: <HorseList />,
          index: true,
        },
        {
          element: <HorsePage />,
          path: ":horseGuid",
        },
        {
          element: <HorseCalendarAdd />,
          path: ":horseGuid/add",
        },
        {
          element: <HorseCalendarEdit detachEvent={false} />,
          path: ":horseGuid/edit/:entryGuid",
        },
        {
          element: <HorseCalendarAdd />,
          path: ":horseGuid/edit-recurrent/:entryGuid",
        },
        {
          element: <HorseCalendarEdit detachEvent={true} />,
          path: ":horseGuid/override-recurrent/:entryGuid",
        },
      ],
    },
    {
      path: "/policy/confidendiality",
      element: <Confidentiality />,
      errorElement: <AppErrorBoundary />,
    },
    {
      path: "/conditions/service",
      element: <ServiceConditions />,
      errorElement: <AppErrorBoundary />,
    },
    {
      path: "/403",
      element: <Page403 />,
      errorElement: <AppErrorBoundary />,
    },
    {
      path: "/pco",
      element: <Home />,
      errorElement: <AppErrorBoundary />,
      children: [
        {
          element: <ActionCards />,
          index: true,
        },
      ],
    },
    {
      path: "*",
      element: <Page404 />,
      errorElement: <AppErrorBoundary />,
    },
  ]);

  return (
    <I18nProvider i18n={i18n}>
      <ThemeProvider theme={darkTheme}>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale="fr"
          localeText={
            frFR.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <RecoilRoot>
              <AppHelmet />
              <SnackBar />
              <Loading />
              <Maintenance />
              <RouterProvider router={router} />
            </RecoilRoot>
          </QueryClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </I18nProvider>
  );
}

export default App;
