import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid2,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useEventTypes,
  useUpdateEventTypes,
} from "../../../Features/Mutations/events";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/system";
import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import EventTypeCard from "./EventTypeCard";

const EventsType = () => {
  const { eventTypes, isLoading } = useEventTypes();
  const [currentEventTypes, setCurrentEventTypes] = useState(null);
  const [expanded, setExpanded] = useState("alert");
  const [isPosting, setIsPosting] = useState(false);

  const mutationEventTypes = useUpdateEventTypes();

  const breadcrumbItems = useMemo(() => {
    return [
      { label: "Accueil", link: "/" },
      { label: "Administration", link: "/administration" },
      { label: "Évènements" },
    ];
  }, []);

  const isChanged = useMemo(() => {
    return JSON.stringify(currentEventTypes) !== JSON.stringify(eventTypes);
  }, [eventTypes, currentEventTypes]);

  const isAlertEnabled = useMemo(() => {
    return !isLoading && currentEventTypes?.alert
      ? currentEventTypes.alert
      : false;
  }, [currentEventTypes, isLoading]);

  const lessonEvents = useMemo(() => {
    return !isLoading && currentEventTypes?.lessons
      ? currentEventTypes.lessons
      : [];
  }, [currentEventTypes, isLoading]);

  const lessonAllowedKeys = useMemo(() => {
    //"Dressage", "Jumping", "Equifun" : the default keys. Allowed only is they are not already used in the current event types
    return ["Dressage", "Jumping", "Equifun"].filter(
      (key) => !lessonEvents.some((event) => event.key === key) || key === ""
    );
  }, [lessonEvents]);

  const competitionEvents = useMemo(() => {
    return !isLoading && currentEventTypes?.competitions
      ? currentEventTypes.competitions
      : [];
  }, [currentEventTypes, isLoading]);

  const competitionAllowedKeys = useMemo(() => {
    return ["Dressage", "Jumping", "Equifun"].filter(
      (key) =>
        !competitionEvents.some((event) => event.key === key) || key === ""
    );
  }, [competitionEvents]);

  const otherEvents = useMemo(() => {
    return !isLoading && currentEventTypes?.other
      ? currentEventTypes.other
      : [];
  }, [currentEventTypes, isLoading]);

  const otherAllowedKeys = useMemo(() => {
    return ["Ride", "WalkRide", "FreeHorse", "FootWork"].filter(
      (key) => !otherEvents.some((event) => event.key === key) || key === ""
    );
  }, [otherEvents]);

  const healthEvents = useMemo(() => {
    return !isLoading && currentEventTypes?.health
      ? currentEventTypes.health
      : [];
  }, [currentEventTypes, isLoading]);

  const healthAllowedKeys = useMemo(() => {
    return ["Dentist", "Marechal", "Vax", "Veterinarian"].filter(
      (key) => !healthEvents.some((event) => event.key === key) || key === ""
    );
  }, [healthEvents]);

  const isAddingEventAllowed = useCallback(
    (category) => {
      //Only if there is event in this category with an empty key
      return (
        currentEventTypes &&
        !currentEventTypes[category].some((event) => event.key === "")
      );
    },
    [currentEventTypes]
  );

  const handleAddNewEvent = useCallback(
    (category) => {
      setCurrentEventTypes({
        ...currentEventTypes,
        [category]: [
          ...currentEventTypes[category],
          {
            key: "",
            label: "",
          },
        ],
      });
    },
    [currentEventTypes]
  );

  const handleEventSaved = useCallback(
    (category, previousKey, event) => {
      // Search the event to update
      const updatedEvents = currentEventTypes[category].find((e) => {
        return e.key === previousKey;
      });

      // Update the event
      updatedEvents.key = event.key;
      updatedEvents.name = event.name;

      // If previous key is empty, remove it
      if (previousKey === "") {
        setCurrentEventTypes({
          ...currentEventTypes,
          [category]: currentEventTypes[category].filter((e) => e.key !== ""),
        });
      } else {
        setCurrentEventTypes({
          ...currentEventTypes,
          [category]: currentEventTypes[category],
        });
      }
    },
    [currentEventTypes]
  );

  const handleEventEditCanceled = useCallback(
    (category, key) => {
      // If key is empty, remove the event
      if (key === "") {
        setCurrentEventTypes({
          ...currentEventTypes,
          [category]: currentEventTypes[category].filter((e) => e.key !== ""),
        });
      }
    },
    [currentEventTypes]
  );

  const handleEventDeleted = useCallback(
    (category, key) => {
      setCurrentEventTypes({
        ...currentEventTypes,
        [category]: currentEventTypes[category].filter((e) => e.key !== key),
      });
    },
    [currentEventTypes]
  );

  const handleChangeAllowsAlerts = useCallback(
    (e) => {
      const { checked } = e.target;
      setCurrentEventTypes({
        ...currentEventTypes,
        alert: checked,
      });
    },
    [currentEventTypes]
  );

  const handleReset = useCallback(() => {
    setCurrentEventTypes(eventTypes);
  }, [eventTypes]);

  const handleSave = useCallback(async () => {
    setIsPosting(true);
    await mutationEventTypes.mutateAsync({ eventTypes: currentEventTypes });
    setIsPosting(false);
  }, [currentEventTypes, mutationEventTypes]);

  const handleOpenAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (!isLoading && eventTypes) {
      setCurrentEventTypes(JSON.parse(JSON.stringify(eventTypes)));
    }
  }, [isLoading, eventTypes]);

  return (
    <Grid2
      container
      spacing={0}
      sx={{
        maxWidth: "100%",
        marginTop: "86px",
      }}
    >
      <CssBaseline />
      <Grid2
        size={12}
        sx={{
          display: "flex",
          alignItems: "baseline",
          xs: { display: "none" },
        }}
      >
        <BreadcrumbComponent items={breadcrumbItems} />
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, mt: 4 }} textAlign={"center"}>
        <Typography variant="body" align="center" color="text.primary">
          Choisissez les types d'évènements proposés sur la page de création
          d'évènements du suivi et plannings des chevaux.
        </Typography>
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, mb: 2 }} textAlign={"center"}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box sx={{ position: "relative", display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={!isChanged || isLoading || isPosting}
              onClick={handleReset}
            >
              Réinitialiser
            </Button>
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              color="primary"
              disabled={!isChanged || isLoading || isPosting}
              onClick={handleSave}
            >
              Enregistrer
            </Button>
            {isPosting || isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  right: -40,
                  top: "25%",
                  transform: "translateY(-50%)",
                }}
              />
            ) : null}
          </Box>
        </Box>
      </Grid2>

      <Grid2 textAlign={"center"} size={12} sx={{ p: 1 }}>
        {isLoading ? (
          "Chargement..."
        ) : (
          <>
            <Accordion
              expanded={expanded === "alert"}
              onChange={handleOpenAccordion("alert")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Alertes</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <FormControlLabel
                  label="Autoriser les alertes"
                  control={
                    <Checkbox
                      checked={isAlertEnabled}
                      onChange={handleChangeAllowsAlerts}
                    />
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "lessons"}
              onChange={handleOpenAccordion("lessons")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Cours</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  {lessonEvents.map((event) => (
                    <EventTypeCard
                      key={`lesson_${event.key}`}
                      category="lessons"
                      event={event}
                      keys={lessonAllowedKeys}
                      onSave={handleEventSaved}
                      onCancel={handleEventEditCanceled}
                      onDelete={handleEventDeleted}
                    />
                  ))}
                </Stack>
              </AccordionDetails>
              <AccordionActions>
                <Button
                  size="small"
                  onClick={() => handleAddNewEvent("lessons")}
                  disabled={!isAddingEventAllowed("lessons")}
                >
                  Ajouter un type de cours
                </Button>
              </AccordionActions>
            </Accordion>
            <Accordion
              expanded={expanded === "competitions"}
              onChange={handleOpenAccordion("competitions")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Concours</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  {competitionEvents.map((event) => (
                    <EventTypeCard
                      key={`competition_${event.key}`}
                      category="competitions"
                      event={event}
                      keys={competitionAllowedKeys}
                      onSave={handleEventSaved}
                      onCancel={handleEventEditCanceled}
                      onDelete={handleEventDeleted}
                    />
                  ))}
                </Stack>
              </AccordionDetails>
              <AccordionActions>
                <Button
                  size="small"
                  onClick={() => handleAddNewEvent("competitions")}
                  disabled={!isAddingEventAllowed("competitions")}
                >
                  Ajouter un type de concours
                </Button>
              </AccordionActions>
            </Accordion>
            <Accordion
              expanded={expanded === "other"}
              onChange={handleOpenAccordion("other")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Autres activités</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  {otherEvents.map((event) => (
                    <EventTypeCard
                      key={`other_${event.key}`}
                      category="other"
                      event={event}
                      keys={otherAllowedKeys}
                      onSave={handleEventSaved}
                      onCancel={handleEventEditCanceled}
                      onDelete={handleEventDeleted}
                    />
                  ))}
                </Stack>
              </AccordionDetails>
              <AccordionActions>
                <Button
                  size="small"
                  onClick={() => handleAddNewEvent("other")}
                  disabled={!isAddingEventAllowed("other")}
                >
                  Ajouter un type d'activité
                </Button>
              </AccordionActions>
            </Accordion>
            <Accordion
              expanded={expanded === "care"}
              onChange={handleOpenAccordion("care")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Soins</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  {healthEvents.map((event) => (
                    <EventTypeCard
                      key={`health_${event.key}`}
                      category="health"
                      event={event}
                      keys={healthAllowedKeys}
                      onSave={handleEventSaved}
                      onCancel={handleEventEditCanceled}
                      onDelete={handleEventDeleted}
                    />
                  ))}
                </Stack>
              </AccordionDetails>
              <AccordionActions>
                <Button
                  size="small"
                  onClick={() => handleAddNewEvent("health")}
                  disabled={!isAddingEventAllowed("health")}
                >
                  Ajouter un type de soin
                </Button>
              </AccordionActions>
            </Accordion>
          </>
        )}
      </Grid2>
    </Grid2>
  );
};

export default EventsType;
