import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useGet, usePatch } from "../apiFetch";

import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { errorAtom } from "../atom";

const useDisplayError = () => {
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  return useCallback(
    (error) => {
      setError({ message: error, severity: "error" });
    },
    [setError]
  );
};

export const useEventTypes = () => {
  const get = useGet();

  const { data, error, isLoading } = useQuery({
    queryKey: ["eventsTypes"],
    queryFn: () => {
      return get(`/configuration/event-types`);
    },
  });

  if (error) {
    console.log("useEventTypes error", data.error);
    return { eventTypes: null, isLoading };
  }
  if (data) {
    if (data.data.error) {
      console.log("useEventTypes error", data.data.error);
      return { eventTypes: null, isLoading };
    }

    return { eventTypes: data?.data || null, isLoading };
  }

  return { eventTypes: null, isLoading };
};

export const useUpdateEventTypes = () => {
  const patch = usePatch();
  const queryClient = useQueryClient();
  const displayError = useDisplayError();

  return useMutation({
    mutationFn: async ({ eventTypes }) => {
      const response = await patch(`/configuration/event-types`, eventTypes);
      if (response.error) {
        throw response.error;
      } else {
        return eventTypes;
      }
    },
    onSuccess: (eventTypes) => {
      queryClient.setQueryData(["eventsTypes"], () => {
        return {
          data: eventTypes,
          statusCode: 200,
        };
      });
    },
    onError: (error) => {
      displayError(
        "Error lors de la mise à jour des types d'événements. Veuiilez réessayer plus tard."
      );
    },
  });
};
