import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2,
  InputAdornment,
  Link,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetHorse,
  useListHorseRiders,
} from "../../../Features/Mutations/horse";
import {
  useCreateCalendarEvent,
  useGetCalendarEvent,
  useUpdateRecurringCalendarEvent,
} from "../../../Features/Mutations/horsecalendar";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import { useEventTypes } from "../../../Features/Mutations/events";

const HorseCalendarAdd = () => {
  const { horseGuid, entryGuid } = useParams();
  const { horse, isLoading } = useGetHorse(horseGuid);
  const { riders, isLoading: isRiderLoading } = useListHorseRiders(horseGuid);
  const addCalendarEvent = useCreateCalendarEvent();
  const updateRecurringCalendarEvent = useUpdateRecurringCalendarEvent(
    horseGuid,
    entryGuid
  );
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const { event, isLoading: isEventLoading } = useGetCalendarEvent(
    horseGuid,
    entryGuid
  );

  const [eventType, setEventType] = useState("");
  const [recurrence, setRecurrence] = useState("Never");
  const [endRecurrence, setEndRecurrence] = useState("Never");
  const [selectedRider, setSelectedRider] = React.useState("");
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()).add(1, "day"));
  const [recurrenceInterval, setRecurrenceInterval] = useState(1);
  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState("");
  const [holiday, setHoliday] = useState("");

  const { eventTypes: refEventTypes, isLoading: isTypesLoading } =
    useEventTypes();

  const picture = useMemo(() => {
    if (horse?.pictureUrl) {
      return `${process.env.REACT_APP_CLOUDFRONT}/${horse?.pictureUrl}`;
    }
    return `${process.env.REACT_APP_CLOUDFRONT}/placeholder.webp`;
  }, [horse?.pictureUrl]);

  const isAlert = useMemo(() => {
    return eventType === "Alert";
  }, [eventType]);

  const isRecurrent = useMemo(() => {
    return recurrence !== "Never";
  }, [recurrence]);

  const hasEndDate = useMemo(() => {
    return endRecurrence !== "Never";
  }, [endRecurrence]);

  const ridersItems = useMemo(() => {
    return isRiderLoading
      ? [
          <MenuItem key="loading" value="">
            <em>Chargement...</em>
          </MenuItem>,
        ]
      : [
          <MenuItem key="none" value="">
            <em>Aucun</em>
          </MenuItem>,
          ...(riders
            ? riders?.map((rider) => (
                <MenuItem key={rider.guid} value={rider.guid}>
                  {rider.name}
                </MenuItem>
              ))
            : []),
        ];
  }, [isRiderLoading, riders]);

  const recurrenceAdornment = useMemo(() => {
    if (recurrence === "Daily") {
      return "jours";
    } else if (recurrence === "Weekly") {
      return "semaines";
    } else if (recurrence === "Monthly") {
      return "mois";
    } else if (recurrence === "Yearly") {
      return "ans";
    }
    return "";
  }, [recurrence]);

  useEffect(() => {
    if (entryGuid && event) {
      setEventType(event.eventType);
      setStartDate(moment(event.date));

      if (event.endDate) {
        setEndDate(moment(event.endDate));
      }

      setRecurrence(event.recurrencePattern);

      if (event.endDate) {
        setEndRecurrence("Le");
      } else {
        setEndRecurrence("Never");
      }

      if (event.recurrenceInterval) {
        setRecurrenceInterval(event.recurrenceInterval);
      }

      setTitle(event.title);
      setNotes(event.description);
      setHoliday(event.includeHolidays ? "on" : "");

      if (event.riderName) {
        riders?.forEach((rider) => {
          if (rider.name === event.riderName) {
            setSelectedRider(rider.guid);
          }
        });
      }
    }
  }, [entryGuid, event, riders]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      const data = Object.fromEntries(formData.entries());
      data.startDate = startDate.format("YYYY-MM-DD");
      data.endDate = endDate.format("YYYY-MM-DD");
      data.horseGuid = horseGuid;

      // If rider is empty, remove it
      if (!data.rider) {
        delete data.rider;
      }

      try {
        if (entryGuid) {
          await updateRecurringCalendarEvent.mutateAsync({
            calendarEvent: data,
          });
        } else {
          await addCalendarEvent.mutateAsync({ calendarEvent: data });
        }
        navigate(`/horse-calendar/${horseGuid}`);
      } catch (error) {
        console.log("Error creating/update event", error);
      }
    },
    [
      addCalendarEvent,
      endDate,
      entryGuid,
      horseGuid,
      navigate,
      startDate,
      updateRecurringCalendarEvent,
    ]
  );

  const handleStartDateChange = useCallback(
    (date) => {
      // Remove time
      const startDate = moment(date).startOf("day");
      setStartDate(startDate);

      // If end date is before start date, set end date to start date + 1 day
      if (startDate.isAfter(endDate)) {
        const newEndDate = moment(startDate).add(1, "day");
        setEndDate(newEndDate);
      }
    },
    [endDate]
  );

  const handleEndDateChange = useCallback((date) => {
    setEndDate(date);
  }, []);

  const handleCancel = useCallback(() => {
    window.history.back();
  }, []);

  const slug = useCallback((str) => {
    //Replace all space characters with -
    str = str.replace(/\s+/g, "-");

    //replace accented characters
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return str;
  }, []);

  return (
    <Grid2
      container
      component="main"
      sx={{
        maxWidth: "100%",
        marginTop: "86px",
      }}
    >
      <CssBaseline />
      <Grid2 size={12}>
        <Breadcrumbs
          separator=""
          aria-label="breadcrumb"
          sx={{ pl: 4, pt: "14px", pb: "7px" }}
        >
          <Link
            underline="hover"
            onClick={() => navigate(`/horse-calendar/${horseGuid}`)}
            sx={{ cursor: "pointer" }}
          >
            <ArrowBackIosNewIcon
              sx={{ color: "GrayText", height: "16px", mt: 0.8 }}
            />
          </Link>
          <Typography variant="h6" align="left" color="text.primary">
            {entryGuid ? "Modification d'un évènement" : "Ajout d'un évènement"}
          </Typography>
        </Breadcrumbs>
      </Grid2>
      <Grid2
        size={{ xs: 0, sm: 0, md: 4 }}
        sx={{
          pl: 4,
        }}
      >
        <img
          src={picture}
          alt={horse?.name}
          style={{ width: "100%", borderRadius: 6 }}
        />
      </Grid2>
      <Grid2
        size={{ xs: 12, sm: 12, md: 8 }}
        sx={{
          px: 4,
        }}
      >
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "rgba(0, 0, 0, 0.08)",
              p: 1,
              borderRadius: 2,
            }}
          >
            <Button
              color="secondary"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Annuler
            </Button>
            {entryGuid && event ? (
              <Typography variant="body1" align="center" color="text.primary">
                {event.title}
              </Typography>
            ) : (
              <Typography variant="body1" align="center" color="text.primary">
                Nouvel évènement
              </Typography>
            )}

            <Button type="submit" disabled={isLoading} startIcon={<AddIcon />}>
              {entryGuid ? "Modifier" : "Ajouter"}
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  marginLeft: "10px",
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              mt: 1,
              maxHeight: { md: "calc(100vh - 340px)", sm: "100%" },
              overflow: "auto",
            }}
          >
            <Paper
              sx={{ m: 1, p: 2, background: "rgba(0, 0, 0, 0.02)" }}
              variant="outlined"
            >
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 4, lg: 3 }}>
                  <FormControl fullWidth>
                    <FormLabel required>Type</FormLabel>
                    <Select
                      name="entryType"
                      required
                      fullWidth
                      value={eventType}
                      onChange={(event) => setEventType(event.target.value)}
                    >
                      {!isTypesLoading && refEventTypes?.alert ? (
                        <MenuItem value="Alert">Note importante</MenuItem>
                      ) : null}
                      {!isTypesLoading &&
                        refEventTypes?.lessons?.length > 0 && [
                          <ListSubheader key="lesson-header">
                            Cours
                          </ListSubheader>,
                          ...refEventTypes.lessons.map((lesson) => (
                            <MenuItem
                              key={`lesson_${slug(lesson.key)}`}
                              value={`lesson_${slug(lesson.key)}`}
                            >
                              {lesson.name}
                            </MenuItem>
                          )),
                        ]}

                      {!isTypesLoading &&
                        refEventTypes?.competitions?.length > 0 && [
                          <ListSubheader key="competitions-header">
                            Concours
                          </ListSubheader>,
                          ...refEventTypes.competitions.map((event) => (
                            <MenuItem
                              key={`event_${slug(event.key)}`}
                              value={`event_${slug(event.key)}`}
                            >
                              {event.name}
                            </MenuItem>
                          )),
                        ]}

                      {!isTypesLoading &&
                        refEventTypes?.other?.length > 0 && [
                          <ListSubheader key="other-header">
                            Autres activités
                          </ListSubheader>,
                          ...refEventTypes.other.map((other) => (
                            <MenuItem
                              key={`other_${slug(other.key)}`}
                              value={`other_${slug(other.key)}`}
                            >
                              {other.name}
                            </MenuItem>
                          )),
                        ]}

                      {!isTypesLoading &&
                        refEventTypes?.health?.length > 0 && [
                          <ListSubheader key="health-header">
                            Soins
                          </ListSubheader>,
                          ...refEventTypes.health.map((health) => (
                            <MenuItem
                              key={`other_${slug(health.key)}`}
                              value={`other_${slug(health.key)}`}
                            >
                              {health.name}
                            </MenuItem>
                          )),
                        ]}
                    </Select>
                  </FormControl>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 8, lg: 9 }}>
                  <FormControl fullWidth>
                    <FormLabel required>Titre</FormLabel>
                    <TextField
                      autoComplete="entry-name"
                      name="entryName"
                      required
                      fullWidth
                      autoFocus
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                    />
                  </FormControl>
                </Grid2>
                {!isAlert && (
                  <Grid2 size={12}>
                    <FormControl fullWidth>
                      <FormLabel>Cavalier</FormLabel>
                      <Select
                        name="rider"
                        fullWidth
                        value={selectedRider}
                        onChange={(event) =>
                          setSelectedRider(event.target.value)
                        }
                      >
                        {ridersItems}
                      </Select>
                    </FormControl>
                  </Grid2>
                )}
              </Grid2>
            </Paper>
            {!isAlert && (
              <Paper
                sx={{ m: 1, p: 2, background: "rgba(0, 0, 0, 0.02)" }}
                variant="outlined"
              >
                <Grid2 container spacing={2}>
                  <Grid2 size={{ xs: 12, md: 4, lg: 3 }}>
                    <FormControl fullWidth>
                      <FormLabel>Date</FormLabel>
                      <MobileDatePicker
                        sx={{ width: "100%" }}
                        slotProps={{
                          toolbar: {
                            sx: {
                              display: "none",
                            },
                          },
                        }}
                        onChange={handleStartDateChange}
                        value={startDate}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 8, lg: 9 }}>
                    <FormControl fullWidth>
                      <FormLabel>Récurrence</FormLabel>
                      <Select
                        name="recurrence"
                        input={<OutlinedInput />}
                        fullWidth
                        value={recurrence}
                        onChange={(event) => setRecurrence(event.target.value)}
                      >
                        {!entryGuid && (
                          <MenuItem value="Never">Jamais</MenuItem>
                        )}
                        <MenuItem value="Daily">Tous les jours</MenuItem>
                        <MenuItem value="Weekly">Toutes les semaines</MenuItem>
                        <MenuItem value="Monthly">Tous les mois</MenuItem>
                        <MenuItem value="Yearly">Tous les ans</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid2>
                  {isRecurrent && (
                    <>
                      <Grid2 size={{ xs: 6, md: 3 }}>
                        <FormControl fullWidth>
                          <FormLabel>Tou(te)s les</FormLabel>
                          <TextField
                            name="recurrenceInterval"
                            type="number"
                            value={recurrenceInterval}
                            slotProps={{
                              htmlInput: {
                                min: 1,
                                max: 365,
                              },
                              input: {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {recurrenceAdornment}
                                  </InputAdornment>
                                ),
                              },
                            }}
                            onChange={(event) =>
                              setRecurrenceInterval(event.target.value)
                            }
                          />
                        </FormControl>
                      </Grid2>
                      <Grid2 size={{ xs: 6, md: 6 }}>
                        <FormControl fullWidth>
                          <FormLabel>Fin de récurrence</FormLabel>
                          <Select
                            name="endRecurrence"
                            input={<OutlinedInput />}
                            fullWidth
                            value={endRecurrence}
                            onChange={(event) =>
                              setEndRecurrence(event.target.value)
                            }
                          >
                            <MenuItem value="Never">Jamais</MenuItem>
                            <MenuItem value="Le">Le</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid2>
                      {hasEndDate && (
                        <Grid2 size={{ xs: 12, md: 3 }}>
                          <FormControl fullWidth>
                            <FormLabel>Date de fin</FormLabel>
                            <MobileDatePicker
                              sx={{ width: "100%" }}
                              slotProps={{
                                toolbar: {
                                  sx: {
                                    display: "none",
                                  },
                                },
                              }}
                              onChange={handleEndDateChange}
                              value={endDate}
                            />
                          </FormControl>
                        </Grid2>
                      )}
                      <Grid2 size={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="holiday"
                              checked={holiday === "on"}
                              onChange={(event) =>
                                setHoliday(event.target.value === "on")
                              }
                            />
                          }
                          label="Inclure vacances scolaires"
                        />
                      </Grid2>
                    </>
                  )}
                </Grid2>
              </Paper>
            )}
            <Paper
              sx={{ m: 1, p: 2, background: "rgba(0, 0, 0, 0.02)" }}
              variant="outlined"
            >
              <Grid2 container spacing={2}>
                <Grid2 size={12}>
                  <FormControl fullWidth>
                    <FormLabel>Notes</FormLabel>
                    <TextField
                      name="entryNotes"
                      multiline
                      rows={4}
                      fullWidth
                      value={notes}
                      onChange={(event) => setNotes(event.target.value)}
                    />
                  </FormControl>
                </Grid2>
              </Grid2>
            </Paper>
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default HorseCalendarAdd;
